import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { catchError, map, Observable, throwError } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { User } from '../../features/users/shared/user.model';
import { CurrentUser } from '../../shared/models/current-user.model';
import { AuthLogIn } from '../../shared/models/auth-log-in.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endpoint: string = environment.apiUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    public router: Router,
    private readonly jwtHelperService: JwtHelperService,
  ) {}

  // Sign-up
  signUp(user: any): Observable<User> {
    let api = `${this.endpoint}/register`;
    return this.http.post<any>(api, user);
  }

  // Sign-in
  signIn(user: AuthLogIn) {
    return this.http.post<any>(`${this.endpoint}/login`, user);
  }

  getToken(): string | null {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('access_token');
    }
    return null;
  }

  getCurrentUser(): CurrentUser {
    if (isPlatformBrowser(this.platformId)) {
      const currentUser = localStorage.getItem('currentUser');
      if (currentUser) {
        return JSON.parse(currentUser) as CurrentUser;
      }
    }
    return {} as CurrentUser;
  }

  isTokenExpired(token: string): boolean {
    let tokenExpired: boolean = true;
    if (token) {
      tokenExpired = this.jwtHelperService.isTokenExpired(token);
    }
    return tokenExpired;
  }

  get isLoggedIn(): boolean {
    let authToken = this.getToken();
    if (authToken) {
      if (this.isTokenExpired(authToken)) {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('currentUser');
        }
        return false;
      }
    }
    return authToken !== null;
  }

  doLogout(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('currentUser');
    }
    if (this.getToken() == null) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.url;
      });
    }
  }

  // User profile
  getUserProfile(id: number): Observable<any> {
    let api = `${this.endpoint}/users/${id}`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res) => {
        return res || {};
      }),
      catchError(this.handleError),
    );
  }

  socialSignIn(provider: string) {
    const redirect = `auth/${provider}/redirect`;
    return this.http.get(`${this.endpoint}/${redirect}`);
  }

  socialCallBack(platform: string, socialCode: string) {
    const callback = `auth/${platform}/callback`;
    return this.http.get<any>(`${this.endpoint}/${callback}?code=${socialCode}`);
  }

  forgotPassword(email: {} | null): Observable<any> {
    return this.http.post(`${this.endpoint}/forgot-password`, email);
  }

  resetPassword(resetPassword: {} | null): Observable<any> {
    return this.http.post(`${this.endpoint}/reset-password`, resetPassword);
  }

  get userRole(): string {
    let userRole = null;
    if (this.isLoggedIn) {
      const currentUserToken = this.getToken();
      if (currentUserToken) {
        userRole = this.jwtHelperService.decodeToken(currentUserToken).user_role;
      }
    }
    return userRole;
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let msg: string;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
